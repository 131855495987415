// Simule le défilement infini sur desktop pour les rayons et la recherche après un premier clic sur le bouton "Voir plus".
// La fonction listenForButtonClick() vérifie si les éléments requis du DOM sont présents. Si c'est le cas, alors on ajoute ou retire un événement "scrollEventlistener".
// Cet événement est également activé et désactivé au début et à la fin de la requête ajax pour éviter un nombre trop conséquent de clics pendant le chargement des produits. Voir => generateNewBlocProd dans functions.js.
function listenForButtonClick() {
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');

    if (button) {
        seeAllBtnClick();
    }
}

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, elle retourne true et le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Fonction qui active ou désactive le scroll listener en fonction de si le premier clic est effectué et de l'avancée de la requête ajax. Voir => generateNewBlocProd.
function seeAllBtnClick(action = null) {
    if (action == "add") {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
    } else if (action == "remove") {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
    } else {
        if (window.hasScrollListener) {
            window.removeEventListener('scroll', handleScroll);
            window.hasScrollListener = false;
        } else {
            window.addEventListener('scroll', handleScroll);
            window.hasScrollListener = true;
        }
    }
}

// Deuxième fonction qui écoute la position du scroll par rapport à la hauteur totale de la page. La condition demande que la fonction isInViewport renvoie true.
function handleScroll() {
    if (document.getElementById('totalElems')) {
        const maxItems = document.getElementById('totalElems').value;
        const productLoaded = $('.productBigPictures').length;
        const pagerNav = document.querySelector('.pagerNav.end_pagi');
        if (pagerNav) {
            const button = pagerNav.querySelector('.loader_scroll');

            // Opacité modifiée et non display car isInViewport() a besoin de l'élément dans le viewport et pas seulement dans le dom pour fonctionner. La jauge de pagination n'apparaîtra que si tous les items ont été chargés dans la page.
            button.style.opacity = '1';
            pagerNav.style.opacity = productLoaded < maxItems ? '0' : '1';

            if (isInViewport(button) && productLoaded < maxItems) {
                button.click();
            }
        }
    }
}

listenForButtonClick();

// Fonction chargée de simuler le clic et de masquer le bouton au retour d'une fiche produit SI le cookie 'p_id' est présent ET si la valeur d'initial_page est supérieure à 1.
$(function () {
    if (getCookie('p_id') && $('#initial_page').val() > 1) {
        seeAllBtnClick();
    }
});