$(function() {
    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

});



$(function(){
    
    if ($('.homepage').length) {
        if($('.simple-gondole').length) {
            var simple = new Swiper('.simple-gondole .swiperTg.swiper-container', {
                slidesPerView: 4,
                navigation: {
                    nextEl: '.gondole-next',
                    prevEl: '.gondole-prev',
                },
            });
        }

        // Home special case for multiple-gondoles
        if ($('.multiple-gondoles').length) {
            // On page load we check the first gondole to see if more than 4 items to prevent swiper-init
            if ($('.multiple-gondoles .swiperTg.active').find('.gondole_item').length > 4) {
                var homeGondole = new Swiper('.multiple-gondoles .swiperTg.active.swiper-container', {
                    slidesPerView: 4,
                    navigation: {
                        nextEl: '.gondole-next',
                        prevEl: '.gondole-prev',
                    },
                });
            }
        }
    }
});
function setActiveGondole(index_module, index_gondole) {
    $('#home_module_'+index_module+' .gondole_titles').removeClass('active');
    $('#home_module_'+index_module+' .gondole_links').removeClass('active');
    $('#home_module_'+index_module+' .gondoles').removeClass('active');

    $('#home_module_'+index_module+' .gondole_title_'+index_gondole).addClass('active');
    $('#home_module_'+index_module+' .gondole_link_'+index_gondole).addClass('active');
    $('#home_module_'+index_module+' .gondole_'+index_gondole).addClass('active');

    // Init gondole only if not already initialized and has more than 4 items
    if ($('#home_module_' + index_module + ' .gondole_' + index_gondole).hasClass('swiper-container') 
    && !$('#home_module_' + index_module + ' .gondole_' + index_gondole).hasClass('swiper-container-initialized')
        && $('#home_module_' + index_module + ' .gondole_' + index_gondole).find('.gondole_item').length > 4){
        var homeGondole = new Swiper('#home_module_' + index_module + ' .gondole_' + index_gondole + '.swiper-container', {
            slidesPerView: 4,
            navigation: {
                nextEl: '.gondole-next',
                prevEl: '.gondole-prev',
            },
        });
    }
}