if($(".slider_cover_module_swiper").length > 0 && $(".slider_cover_module_swiper .swiper-slide").length > 1) {
    var sliderCoverModuleSwiper0 = new Swiper('.slider_cover_module_swiper', {
        speed: 1500,
        autoplay: {
            delay: 8000,
        },
        navigation: {
            nextEl: '.cover-next',
            prevEl: '.cover-prev',
        },
    });
}

if($(".products_list .swiper-container").length > 4) {
    var cmsProductListSwiper = new Swiper('.products_list .swiper-container', {
        slidesPerView: 4, 
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

if($("#articles_list_swiper").length > 0) {
    var articlesListSwiper = new Swiper('#articles_list_swiper', {
        slidesPerView: 2, 
        spaceBetween: 100,
        navigation: {
            nextEl: '.articles-list-swiper-button-next',
            prevEl: '.articles-list-swiper-button-prev',
        },
    });
}

$.each($('.cms_page .cmspage_productswiper'), function() {
    if ($(this).find('.swiper-slide').length > 4) {
        new Swiper($(this).get(), {
            slidesPerView: 4,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
});